@font-face {
  font-family: "Aero Matics Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Aero Matics Regular"),
    url("../fonts/Aero Matics Regular.woff") format("woff");
}

@font-face {
  font-family: "Aero Matics Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Aero Matics Italic"),
    url("../fonts/Aero Matics Italic.woff") format("woff");
}

@font-face {
  font-family: "Aero Matics Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Aero Matics Bold"),
    url("../fonts/Aero Matics Bold.woff") format("woff");
}

@font-face {
  font-family: "Aero Matics Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Aero Matics Bold Italic"),
    url("../fonts/Aero Matics Bold Italic.woff") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  background: linear-gradient(#cfd1d2, #f8f8f8, #ffffff, #bfbfbf) !important;
  background: -webkit-linear-gradient(
    #cfd1d2,
    #f8f8f8,
    #ffffff,
    #bfbfbf
  ) !important;
  position: relative;
  font-family: Aero Matics Regular, Helvetica, sans-serif !important;
  font-weight: normal;
  color: #0090d6;
}

.logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 120px;
  height: auto;
  margin: 1rem;
  z-index: 9996;
}

@media screen and (max-width: 992px) {
  .logo {
    position: absolute;
    width: 100px;
  }
}

.modal-backdrop.show {
  opacity: 0.9 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 20% auto 0 !important;
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0.8rem !important;
  outline: 0;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center !important;
  padding: 1rem 1rem;
  border-bottom: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.modal-title {
  width: 100%;
  text-align: center !important;
  color: #0090d6;
  font-size: 2rem !important;
}

.modal-body {
  color: #0090d6;
  font-size: 1.2rem !important;
  text-align: center !important;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center !important;
  padding: 0.75rem;
  border-top: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.enter-button {
  background-color: #0090d6 !important;
  border: none !important;
  padding: 0.8rem 1.8rem !important;
  border-radius: 0.8rem !important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;
  z-index: 9999;
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.can-wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: linear-gradient(#cfd1d2, #f8f8f8, #ffffff, #bfbfbf);
}

@media screen and (max-width: 992px) {
  .can-wrapper {
    position: relative;
    height: 89vh;
    background: transparent;
  }
}

.App {
  width: calc(100vw - 450px);
  height: 100%;
}

@media screen and (max-width: 992px) {
  .App {
    position: relative;
    width: 310px;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
}

.info-scroll {
  display: none;
  font-size: 1.9rem !important;
  text-align: center;
  color: #0090d6;
  margin-bottom: 3rem;
}

@media screen and (max-width: 992px) {
  .info-scroll {
    display: block;
  }
}

.information-wrapper {
  position: absolute;
  width: 450px;
  right: 0;
}

@media screen and (max-width: 992px) {
  .information-wrapper {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 500px) {
  .information-wrapper {
    width: 100vw;
  }
}

.info-wrap-fix {
  margin: 2rem 2rem 4rem 2rem;
  border-radius: 0.8rem;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

@media screen and (max-width: 992px) {
  .info-wrap-fix {
    position: relative;
  }
}

@media screen and (max-width: 500px) {
  .info-wrap-fix {
    margin: 0;
    box-shadow: none;
  }
}

.label-wrapper {
  height: fit-content;
  max-width: 450px;
  margin-bottom: 1rem;
  background-color: rgba(#fff, 0.8);
  padding: 1rem;
  border-radius: 0.8rem;
}

.label-title-img {
  width: 200px;
}

.drip-img {
  height: 50px;
}

.label-wrapper .title {
  text-align: center;
  color: #0090d6;
}

.label-wrapper .facts {
  padding: 0 1rem 1rem;
}

.nutri-content {
  border: 2px solid #0090d6;
  border-radius: 0.8rem;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(#fff, 0.3);
  color: #0090d6;
}

.nutri-title {
  font-size: 1.9rem !important;
  text-align: center;
  color: #0090d6;
}

.nutrition {
  columns: 3;
}

.nutrition ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
}

.nutrition ul li {
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
}

.about-content {
  margin-bottom: 1rem;
  background-color: rgba(#fff, 0.7);
  padding: 1rem;
  border-radius: 0.8rem;
}
.about-content h2,
.about-content p {
  color: #0090d6;
}

.stockists-content {
  background-color: rgba(#fff, 0.7);
  padding: 1rem;
  border-radius: 0.8rem;
}
.stockists-content .stockist-card {
  text-align: center;
  padding: 1rem;
  border: 2px solid #0090d6;
  border-radius: 0.8rem;
  background-color: rgba(#fff, 0.3);
}

.footer-wrapper {
  padding: 1rem;
  border-bottom: none;
  border-radius: 0.8rem 0.8rem 0 0;
  text-align: center;
  color: #0090d6;
}

.insta-icon-img {
  width: 30px;
  padding: 5px;
}
